import { Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import api from "../../Helper/API";
import CardComponent from "../MenuBarComponent/MenuLogoSection/CardComponent";

export default function ClientCarousalSection() {
  const [refresh, setRefresh] = useState("xyz");
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [text, setText] = useState("");

  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [homeclient_id, sethomeclient_id] = useState("");

  // const [serviceImg, setServiceImg] = useState();
  // const [imgText, setImgText] =useState("");
  // const [destinationURL, setDestinationURL] =useState("");

  useEffect(async () => {
    const result = await api.post("/getHomeClient");
    console.log(result.data.data);
    setTitle(result.data.data[0].title);
    setSubTitle(result.data.data[0].subtitle);
    setText(result.data.data[0].text);
    sethomeclient_id(result.data.data[0]._id);

    //   const res = await api.post("");
    //   setServiceImgList(res.data.data)
  }, [refresh]);

  const handlePortfolioTitle = async (e) => {
    e.preventDefault();
    const result = await api.post("/updateHomeClient", {
      title,
      subtitle,
      text,
      homeclient_id,
    });
    message.success(result.data.message);
    setRefresh("1");
  };

  const handleServiceImage = () => {};

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const labelstyle={fontSize:"medium " ,fontWeight:"500",paddingTop:'1.5rem'}
  const inputstyle = { width: "30%", margin: "2%", fontStyle: "italic " ,borderRadius: '5px'};

  return (
    <>
      <div className="p-4">
        <div style={{ width: "100%" }}>
          <CardComponent title="Client Carousal  title Setting ">
            <Card>
              <Form>
                <div style={outerstyle}>
                  <div className="col-lg-2">
                    <label htmlFor="" style={labelstyle}>Client sub title</label>
                  </div>
                  <div className="col-lg-10">
                    <div>
                      <Form.Control
                      className="w-100"
                        style={inputstyle}
                        value={subtitle}
                        onChange={(e) => {
                          setSubTitle(e.target.value);
                        }}
                        placeholder="Enter Client Carousal Sub-Title"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <label style={labelstyle}  htmlFor="">Client carousal title</label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                    <Form.Control
                    className="w-100"
                    style={inputstyle}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    placeholder="Enter Client Carousal Title"
                  />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <label style={labelstyle} htmlFor="">Client carousal text</label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                    <Form.Control
                    className="w-100"
                    style={inputstyle}
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    placeholder="Enter Client Carousal text"
                  />
                    </div>
                  </div>
                 <div className="col-lg-12">
                  <div className="my-3"> 
                  <Button
                    style={{ borderRadius: '5px', backgroundColor: '#2B3A4A', border: 'none'  }}
                    variant="success"
                    onClick={handlePortfolioTitle}
                  >
                    UPDATE
                  </Button>
                  </div>
                 </div>
                  
                </div>
              </Form>
            </Card>
          </CardComponent>
        </div>
      </div>
    </>
  );
}
