import React from 'react'
import WhyToChooseUsTitleSection from './WhyToChooseUsTitleSection';
import WhyToChooseUsListSection from './WhyToChooseUsListSection';

const WhyToChooseUsSection = () => {
  return (
    <>
    <div>
        <WhyToChooseUsTitleSection />
        <WhyToChooseUsListSection />
    </div>
    </>
  )
}

export default WhyToChooseUsSection;