import { Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import api from "../../Helper/API";
import { uploadFile } from "../../Helper/helper";
import "../../CSS/allcss.css";
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

export default function AboutSection() {
  const [refresh, setRefresh] = useState("xyz");
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [button_url, setButton_url] = useState("");
  const [button_text, setButton_text] = useState("");
  const [image1_url, setImage1_url] = useState("");
  const [image2_url, setImage2_url] = useState("");
  const [homeabout_id, setHomeabout_id] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload1 = async () => {
    let imgUrl = await uploadFile(
      selectedFile,
      `${selectedFile.name}fileimg_${Date.now()}`
    );
    setImage1_url(imgUrl);
  };

  const handleUpload2 = async () => {
    let imgUrl = await uploadFile(
      selectedFile,
      `${selectedFile.name}fileimg_${Date.now()}`
    );
    setImage2_url(imgUrl);
  };

  useEffect(async () => {
    const result = await api.post("/getHomeAbout");
    console.log(result.data.data);
    setTitle(result.data.data[0].title);
    setSubTitle(result.data.data[0].subtitle);
    setText(result.data.data[0].text);
    setButton_text(result.data.data[0].button_text);
    setButton_url(result.data.data[0].button_url);
    setImage1_url(result.data.data[0].image1_url);
    setImage2_url(result.data.data[0].image2_url);
    setHomeabout_id(result.data.data[0]._id);
  }, [refresh]);

  const handleAboutTitle = async (e) => {
    e.preventDefault();
    const result = await api.post("/updateHomeAbout", {
      title: title,
      subtitle: subtitle,
      text: text,
      button_text: button_text,
      button_url: button_url,
      image1_url: image1_url,
      image2_url: image2_url,
      homeabout_id: homeabout_id,
    });
    message.success(result.data.message);
    setRefresh("1");
    document.getElementById("f1").value = "";
    document.getElementById("f2").value = "";
  };

  const inputstyle = { width: "100%", margin: "1%", fontStyle: "italic" };
  const btnstyle = { backgroundColor: "#2B3A4A", border: "none" };
  const cardStyle = { width: "100%", marginBottom: "10px" };

  return (
    <div style={{ width: "100%" }}>
      <Card style={cardStyle}>
        <div className="card p-0 card-shadow">
          <div className="card-header" style={{ backgroundColor: "#F7F7F7" }}>
            <h5 className="fs-5 mb-0 p-2">About Section title Setting </h5>
          </div>
          <div className="card-body" style={{ backgroundColor: "#fff" }}>
            <Form className="m-1 p-4">
              <div className="main">
                <div className="row py-1">
                  <div className="col-sm-12 col-md-2">
                    <label className="label-about pt-3">Company Name </label>
                  </div>
                  <div className="col-sm-12 col-md-10">
                    <Form.Control
                      className="w-100"
                      style={inputstyle}
                      value={subtitle}
                      onChange={(e) => {
                        setSubTitle(e.target.value);
                      }}
                      placeholder="Enter About Sub-Title"
                    />
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-sm-12 col-md-2">
                    <label className="label-about pt-3">Description </label>
                  </div>
                  <div className="col-sm-12 col-md-10">
                    <Form.Control
                      className="w-100"
                      style={inputstyle}
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      placeholder="Enter About Title"
                    />
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-sm-12 col-md-2">
                    <label className="label-about pt-3">Address </label>
                  </div>
                  <div className="col-sm-12 col-md-10">
                    <Form.Control
                      className="w-100"
                      style={inputstyle}
                      value={text}
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                      placeholder="Enter About text"
                    />
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-sm-12 col-md-2">
                    <label className="label-about pt-3">About</label>
                  </div>
                  <div className="col-sm-12 col-md-10">
                    <Form.Control
                      className="w-100"
                      style={inputstyle}
                      value={button_url}
                      onChange={(e) => {
                        setButton_url(e.target.value);
                      }}
                      placeholder="Enter About Button URL"
                    />
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-sm-12 col-md-2">
                    <label className="label-about pt-3">More</label>
                  </div>
                  <div className="col-sm-12 col-md-10">
                    <Form.Control
                      className="w-100"
                      style={inputstyle}
                      value={button_text}
                      onChange={(e) => {
                        setButton_text(e.target.value);
                      }}
                      placeholder="Enter Button text"
                    />
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-sm-12 col-md-2">
                    <label className="label-about pt-3">Upload</label>
                  </div>
                  <div className="col-sm-12 col-md-10">
                    <Form.Control
                      className="w-100"
                      style={inputstyle}
                      id="f1"
                      onBlur={handleUpload1}
                      type="file"
                      onChange={(e) => {
                        setSelectedFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-sm-12 col-md-2">
                    <label className="label-about pt-3">URL</label>
                  </div>
                  <div className="col-sm-12 col-md-10">
                    <Form.Control
                      className="w-100"
                      style={inputstyle}
                      value={image1_url}
                      onChange={(e) => {
                        setImage1_url(e.target.value);
                      }}
                      placeholder="Enter Image 1 URL "
                    />
                  </div>
                </div>
                <div className="py-3">
                  <Button
                    style={btnstyle}
                    variant="success"
                    onClick={handleAboutTitle}
                  >
                    UPDATE
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );
}
