import React from 'react';
import { Card, Tabs } from 'antd';
import MenuBarSettingComponent from '../Component/MenuBarComponent/MenuBarSettingSection';

import MenuLogoSection from '../Component/MenuBarComponent/MenuLogoSection';
import PortfolioListComponent from '../Component/PortfolioListComponet';
import ServicesListSection from '../Component/ServicesListSection';



export default function ServicesSetting() {
  
const { TabPane } = Tabs;
function callback(key) {
//   console.log(key);
}
  return (
    <div>
      <Card>
      <Tabs defaultActiveKey="1" onChange={callback} size="large" centered>

     
        <TabPane tab="Services Page Setting" key="1">
          <ServicesListSection/>
        </TabPane>
        
    
     </Tabs>
     </Card>
    </div>
  )
}
