import React from 'react';
import { Card } from 'react-bootstrap';

const CardComponent = ({ children, title }) => {
  return (
    
    <Card className="card p-0 card-shadow">
      <div className="card-header">
        <p className="fs-5 mb-0 p-2" style={{ fontWeight: '500' }}>
          {title}
        </p>
      </div>
      <div className="card-body" style={{ backgroundColor: '#fff' }}>
        {children}
      </div>
    </Card>
  );
};

export default CardComponent;
