import { Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import CardComponent from "../MenuBarComponent/MenuLogoSection/CardComponent";
import api from "../../Helper/API";
import { Buffer } from "buffer";
import { uploadFile } from "../../Helper/helper";
window.Buffer = window.Buffer || Buffer;

export default function ExperienceSection() {
  const [refresh, setRefresh] = useState("xyz");
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [video_text, setVideo_text] = useState("");
  const [video_url, setVideo_url] = useState("");
  const [homeexperience_id, setHomeexperience_id] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload1 = async () => {
    let imgUrl = await uploadFile(
      selectedFile,
      `${selectedFile.name}${Date.now()}`
    );
    setVideo_url(imgUrl);
    // alert("video uploded on",imgUrl );
  };

  useEffect(async () => {
    const result = await api.post("/getHomeExp");
    console.log(result.data.data);
    setTitle(result.data.data[0].title);
    setSubTitle(result.data.data[0].subtitle);
    setText(result.data.data[0].text);
    setVideo_text(result.data.data[0].video_text);
    setVideo_url(result.data.data[0].video_url);
    setHomeexperience_id(result.data.data[0]._id);

    //   const res = await api.post("");
    //   setServiceImgList(res.data.data)
  }, [refresh]);

  const handleAboutTitle = async (e) => {
    // alert(homeabout_id)
    e.preventDefault();
    const result = await api.post("/updateHomeExp", {
      text,
      subtitle,
      title,
      video_text,
      video_url,
      homeexperience_id,
    });
    message.success(result.data.message);
    setRefresh("1");
    document.getElementById("f2").value = "";
  };

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const labelstyle = { fontSize: "medium ", fontWeight: "500" };
  const inputstyle = {
    width: "30%",
    margin: "2%",
    fontStyle: "italic ",
    borderRadius: "5px",
  };
  return (
    <div className="p-4">
    <div style={{ width: "100%" }}>
      <CardComponent title="Experience Section title Setting ">
        <Card>
          <Form className="pt-2 p-0" style={{ background: "none" }}>
            <div style={outerstyle}>
              <div className="col-md-2">
                <label className="pt-md-4" style={labelstyle}>
                  Sub-Title
                </label>
              </div>
              <div className="col-md-10">
                <Form.Control
                  className="w-100"
                  style={inputstyle}
                  value={subtitle}
                  onChange={(e) => {
                    setSubTitle(e.target.value);
                  }}
                  placeholder="Enter About Sub-Title"
                />
              </div>
              <div className="col-md-2">
                <label className="pt-md-4" style={labelstyle}>
                  About-Title
                </label>
              </div>
              <div className="col-md-10">
                <Form.Control
                  className="w-100"
                  style={inputstyle}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  placeholder="Enter About Title"
                />
              </div>
              <div className="col-md-2">
                <label className="pt-md-4" style={labelstyle}>
                  About-Text
                </label>
              </div>
              <div className="col-md-10">
                <Form.Control
                  className="w-100"
                  style={inputstyle}
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  placeholder="Enter About text"
                />
              </div>
              <div className="col-md-2">
                <label className="pt-md-4" style={labelstyle}>
                  Button-URL
                </label>
              </div>
              <div className="col-md-10">
                <Form.Control
                  className="w-100"
                  style={inputstyle}
                  value={video_text}
                  onChange={(e) => {
                    setVideo_text(e.target.value);
                  }}
                  placeholder="Enter About Button URL"
                />
              </div>
              <div className="col-md-2">
                <label className="pt-md-4" style={labelstyle}>
                  File-Choose
                </label>
              </div>
              <div className="col-md-10">
                <Form.Control
                  className="w-100"
                  style={inputstyle}
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    // handleUpload1();
                  }}
                />
              </div>

             <div className="col-md-2">
             <div className="my-3 d-block">
                <Button
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#2B3A4A",
                    border: "none",
                  }}
                  onClick={() => handleUpload1()}
                >
                  {" "}
                  Upload
                </Button>
              </div>
             </div>
             <div className="col-md-2">
             <div className="m-3">
                <Button  style={{
                    borderRadius: "5px",
                    backgroundColor: "#2B3A4A",
                    border: "none",
                  }} variant="success" onClick={handleAboutTitle}>
                  UPDATE
                </Button>
              </div>
             </div>
            </div>
          </Form>
        </Card>
      </CardComponent>
    </div>
    </div>
  );
}
