import { Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import api from "../../Helper/API";
import { Buffer } from "buffer";
import { uploadFile } from "../../Helper/helper";
import CardComponent from "../MenuBarComponent/MenuLogoSection/CardComponent";
window.Buffer = window.Buffer || Buffer;

export default function QuoteSection() {
  const [refresh, setRefresh] = useState("xyz");
  const [image_url, setimage_url] = useState("");
  const [icon_url, setIcon_url] = useState("");
  const [quote, setquote] = useState("");
  const [text, settext] = useState("");
  const [quote_id, setquote_id] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload1 = async () => {
    let imgUrl = await uploadFile(
      selectedFile,
      `${selectedFile.name}fileimg_${Date.now()}`
    );
    setIcon_url(imgUrl);
  };
  const handleUpload2 = async () => {
    let imgUrl = await uploadFile(
      selectedFile,
      `${selectedFile.name}fileimg_${Date.now()}`
    );
    setimage_url(imgUrl);
  };

  useEffect(async () => {
    const result = await api.post("/getQuote");
    console.log(result.data.data);
    setquote(result.data.data[0].quote);
    setimage_url(result.data.data[0].image_url);
    setIcon_url(result.data.data[0].icon_url);
    settext(result.data.data[0].text);
    setquote_id(result.data.data[0]._id);
  }, [refresh]);

  const handlequote = async (e) => {
    // alert(quote_id)
    e.preventDefault();
    const result = await api.post("/updateQuote", {
      image_url,
      icon_url,
      quote,
      text,
      quote_id,
    });
    console.log("first", result);
    message.success(result.data.message);
    setRefresh("1");
    document.getElementById("f1").value = "";
    document.getElementById("f2").value = "";
  };

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const inputstyle = {
    margin: "2%",
    fontStyle: "italic ",
    borderRadius: "5px",
  };
  const labelstyle = {
    fontSize: "medium ",
    fontWeight: "500",
    paddingTop: "1.5rem",
  };

  return (
    <>
      <div className="p-4">
        <div style={{ width: "100%" }}>
          <CardComponent title="Quote Section Setting ">
            <Card>
              <Form>
                <div style={outerstyle}>
                  <div className="col-lg-2">
                    <div>
                      <label style={labelstyle} htmlFor="">
                        File Choose
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                      <Form.Control
                        style={inputstyle}
                        type="file"
                        id="f1"
                        onBlur={handleUpload1}
                        onChange={(e) => {
                          setSelectedFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <label style={labelstyle} htmlFor="">
                        Enter icon url
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                      <Form.Control
                        style={inputstyle}
                        value={icon_url}
                        onChange={(e) => {
                          setIcon_url(e.target.value);
                        }}
                        placeholder="Enter Icon URL"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <label style={labelstyle} htmlFor="">
                        File choose
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                      <Form.Control
                        style={inputstyle}
                        type="file"
                        id="f2"
                        onBlur={handleUpload1}
                        onChange={(e) => {
                          setSelectedFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <label htmlFor="" style={labelstyle} >
                        Image url
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                    <Form.Control
                    style={inputstyle}
                    value={image_url}
                    onChange={(e) => {
                      setimage_url(e.target.value);
                    }}
                    placeholder="Enter Image URL"
                  />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <label style={labelstyle} htmlFor="">
                        Enter quote
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                    <Form.Control
                    style={inputstyle}
                    value={quote}
                    onChange={(e) => {
                      setquote(e.target.value);
                    }}
                    placeholder="Enter Quote "
                  />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div>
                      <label style={labelstyle} htmlFor="">Enter description text</label>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div>
                    <Form.Control
                    style={inputstyle}
                    value={text}
                    onChange={(e) => {
                      settext(e.target.value);
                    }}
                    placeholder="Enter description text"
                  />
                    </div>
                  </div>
               
                  
                  
                  <div className="my-3">
                    <Button style={{ borderRadius: '5px', backgroundColor: '#2B3A4A', border: 'none'  }} variant="success" onClick={handlequote}>
                      UPDATE
                    </Button>
                  </div>{" "}
                </div>
              </Form>
            </Card>
          </CardComponent>
        </div>
      </div>
    </>
  );
}
