import React, { useState, useEffect } from "react";
import { Card, Table, Space, message } from "antd";
import { Button, Form } from "react-bootstrap";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import api from "../Helper/API";
import { Buffer } from "buffer";
import { uploadFile } from "../Helper/helper";
import CKEditor from "react-ckeditor-component";
window.Buffer = window.Buffer || Buffer;

export default function PortfolioListComponent() {
  const [data1, setData1] = useState([]);
  const [thumbnailImageFile, setThumbnailImageFile] = useState();
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState("");
  const [thumbnail_image, setthumbnail_image] = useState("");
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [porftolioImageUrls, setPortfolioImageUrls] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [project_link, setProject_link] = useState("");
  const [dateOfProject, setDateOfProject] = useState("");
  const [clientName, setClientName] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [introduction, setIntroduction] = useState("");

  const [edit, setEdit] = useState(false);
  const [portfolio_id, setportfolio_id] = useState();
  const [refresh, setRefresh] = useState("start");

  useEffect(async () => {
    const res = await api.post("/getPortfolio");
    setData1(res.data.data);
    console.log("11", res.data.data);
    console.log(data1);
  }, [refresh]);

  // setTimeout(() => {
  //   console.log("tt",data1,"tt")
  // }, 1000);

  const handleSubmit = async (e) => {
    if (edit) {
      let updateData = {
        thumbnail_image: thumbnailImageUrl,
        images: porftolioImageUrls,
        title,
        content,
        project_link,
        portfolio_id,
        subtitle,
        date_of_project: dateOfProject,
        client: clientName,
        category,
        tags,
        introduction,
      };
      if (thumbnailImageFile) {
        const thumbnailImageUrl = await uploadFile(
          thumbnailImageFile,
          `portfolio_thumbnail_${Date.now()}.png`
        );
        updateData.thumbnail_image = thumbnailImageUrl;
      }
      if (portfolioImages.length !== 0) {
        const imageUrls = await Promise.all(
          portfolioImages.map((image, index) =>
            uploadFile(image, `portfolio_image_${index}_${Date.now()}.png`)
          )
        );
        updateData.images = imageUrls;
      }
      const result = await api.post("/updatePortfolio", updateData);
      message.success(result.data.message);
      setthumbnail_image("");
      setContent("");
      setTitle("");
      setProject_link("");
      setEdit(false);
      setportfolio_id("");
      setRefresh("1");
    } else {
      const thumbnailImageUrl = await uploadFile(
        thumbnailImageFile,
        `portfolio_thumbnail_${Date.now()}.png`
      );
      const imageUrls = await Promise.all(
        portfolioImages.map((image, index) =>
          uploadFile(image, `portfolio_image_${index}_${Date.now()}.png`)
        )
      );
      const result = await api.post("/addPortfolio", {
        thumbnail_image: thumbnailImageUrl,
        images: imageUrls,
        title,
        content,
        project_link,
        subtitle,
        date_of_project: dateOfProject,
        client: clientName,
        category,
        tags,
        introduction,
      });
      // console.log(result)
      message.success(result.data.message);
      setthumbnail_image("");
      setContent("");
      setTitle("");
      setProject_link("");
      setEdit(false);
      setportfolio_id("");
      setRefresh("2");
      setDateOfProject("");
      setCategory("");
      setClientName("");
      setIntroduction("");
      setTags("");
    }
    // e.preventDefault();
  };

  const handleEdit = (data) => {
    setThumbnailImageUrl(data.thumbnail_image);
    setthumbnail_image(data.thumbnail_image);
    setTitle(data.title);
    setSubtitle(data.subtitle);
    setPortfolioImageUrls(data.images);
    setContent(data.content);
    setProject_link(data.project_link);
    setportfolio_id(data._id);
    setDateOfProject(data.date_of_project);
    setCategory(data.category);
    setClientName(data.client);
    setTags(data.tags);
    setIntroduction(data.introduction);
    // console.log(data)
  };

  function onDescriptionEditorContentChange(evt) {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setContent(newContent);
  }

  function onIntroductionEditorContentChange(evt) {
    let newContent = evt.editor.getData();
    setIntroduction(newContent);
  }

  const handleDelete = async (data) => {
    const result = await api.post("/updatePortfolio", {
      portfolio_id: data._id,
      delete_status: true,
    });
    message.success(result.data.message);
    setRefresh("3");
    console.log(result);
  };

  const columns1 = [
    {
      title: "Thumbnaill Image URL ",
      dataIndex: "thumbnail_image",
      key: "thumbnail_image",
      render: (text) => text.substring(0, 20),

      //   render: text => <a>{text}</a>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "Project Link",
      dataIndex: "project_link",
      key: "project_link",
      render: (text) => text.substring(0, 20),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleEdit(text);
                setEdit(true);
              }}
            >
              <EditOutlined />
            </Button>
          </a>
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleDelete(text);
              }}
            >
              <DeleteOutlined />
            </Button>
          </a>
          {/* <a><Button onClick={handleDelete(text)}>Edit</Button></a> */}
        </Space>
      ),
    },
  ];

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const inputstyle = { width: "35%", margin: "1%" };
  return (
    <div>
      {/* form for add hero slider   */}
      <Card title=" Portfolio Setting">
        <Form>
          <div style={outerstyle}>
            <Form.Control
              style={inputstyle}
              id="f1"
              type="file"
              placeholder="Select portfolio thumbnail"
              onChange={(e) => {
                setThumbnailImageFile(e.target.files[0]);
              }}
            />
            {thumbnailImageFile && (
              <img
                width={50}
                height={50}
                src={URL.createObjectURL(thumbnailImageFile)}
              />
            )}
            <Form.Control
              style={inputstyle}
              id="f2"
              type="file"
              multiple
              placeholder="Select portfolio images"
              onChange={(e) => {
                setPortfolioImages(Array.from(e.target.files));
              }}
            />
            {portfolioImages.map((image) => {
              return (
                <img width={50} height={50} src={URL.createObjectURL(image)} />
              );
            })}
            <Form.Control
              style={inputstyle}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Enter Portfolio Title"
            />
            <Form.Control
              style={inputstyle}
              value={project_link}
              onChange={(e) => {
                setProject_link(e.target.value);
              }}
              placeholder="Enter project Link"
            />
            <Form.Control
              style={inputstyle}
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value);
              }}
              placeholder="Enter portfolio subtitle"
            />
            <Form.Control
              style={inputstyle}
              value={dateOfProject}
              onChange={(e) => {
                setDateOfProject(e.target.value);
              }}
              placeholder="Enter project date"
            />
            <Form.Control
              style={inputstyle}
              value={clientName}
              onChange={(e) => {
                setClientName(e.target.value);
              }}
              placeholder="Enter client name"
            />
            <Form.Control
              style={inputstyle}
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              placeholder="Enter project category"
            />
            <Form.Control
              value={tags}
              onChange={(e) => {
                setTags(e.target.value);
              }}
              placeholder="Enter project tags"
            />
            <div style={{ margin: "1%" }}>
              <CKEditor
                activeClass="p10"
                placeholder="Enter project introduction"
                content={introduction}
                events={{
                  // "blur": onBlur,
                  // "afterPaste": afterPaste,
                  change: onIntroductionEditorContentChange,
                }}
              />{" "}
            </div>
            {/* <Form.Control style={inputstyle} value={project_link} onChange={(e) => { setProject_link(e.target.value) }} placeholder="Enter project Link" /> */}
            {/* <Form.Control
              as="textarea"
              rows={6}
              style={inputstyle}
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              placeholder="Enter Content"
            /> */}
            <div style={{ margin: "1%" }}>
              <CKEditor
                activeClass="p10"
                content={content}
                events={{
                  // "blur": onBlur,
                  // "afterPaste": afterPaste,
                  change: onDescriptionEditorContentChange,
                }}
              />{" "}
            </div>
          </div>
          <div>
            {" "}
            <Button
              variant="success"
              style={{ margin: "1%", textAlign: "center" }}
              onClick={handleSubmit}
            >
              {edit ? "Update" : "ADD"}
            </Button>
          </div>
        </Form>
      </Card>

      <Card title="List of Portfolio ">
        <Table dataSource={data1} columns={columns1}></Table>
      </Card>
    </div>
  );
}
