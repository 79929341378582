import React, { useState, useEffect } from "react";
import { Card, Table, Space, message } from "antd";
import { Button, Form } from "react-bootstrap";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import api from "../../Helper/API";
// import { useHistory } from "react-router-dom";

import { Buffer } from "buffer";
import { uploadFile } from "../../Helper/helper";
window.Buffer = window.Buffer || Buffer;
// const history = useHistory();

export default function TestimonialCrousalSection() {
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [testimonial, setTestimonial] = useState({
    project_title: "",
    client_name: "",
    rating: "",
    start_date: "",
    end_date: "",
    content: "",
    client_image: "",
  });
  const [testimonialcrousal_id, setTestimonialcrousal_id] = useState();
  const [refresh, setRefresh] = useState("start");
  const [edit, setEdit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload1 = async (file) => {
    let imgUrl = await uploadFile(file, `${file.name}fileimg_${Date.now()}`);
    setTestimonial({ ...testimonial, client_image: imgUrl });
    // document.getElementById("f1").value = "";
  };

  useEffect(async () => {
    const res = await api.post("/getHomeRecommend");
    // console.log("c", res.data.data)
    setData(res.data.data);
    //   console.log("11",res)
  }, [refresh]);

  const formatDate = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return newDate;
  };
  const formatDate2 = (e) => {
    const date = new Date(e);
    return date.toISOString().split("T")[0];
  };

  const handleSubmit = async (e) => {
    // if (edit) {
    //   console.log("true", edit);
    //   const result = await api.post("/updateTestimonialCrousal", {
    //     name,
    //     position,
    //     feedback,
    //     image_url,
    //     testimonialcrousal_id,
    //   });
    //   message.success(result.data.message);
    //   setName("");
    //   setImage_url("");
    //   setPosition("");
    //   setFeedback("");
    //   setEdit(false);
    //   setRefresh("1");
    //   setSelectedFile(null);
    //   document.getElementById("f8").value = "";
    // } else {
    if (isEdit) {
      console.log("edit");
      const result = await api.post("/updatehomerecommend", {
        ...testimonial,
        homerecommend_id: editId,
      });
      // console.log(result)
      message.success(result.data.message);
      setTestimonial({
        project_title: "",
        client_name: "",
        rating: "",
        start_date: "",
        end_date: "",
        content: "",
        client_image: "",
      });
      setIsEdit(false);
      setEditId("");
      setRefresh("2");
      document.getElementById("f8").value = "";
    } else {
      console.log("add");
      const result = await api.post("/addhomerecommend", testimonial);
      // console.log(result)
      message.success(result.data.message);
      setEdit(false);
      setRefresh("2");
      document.getElementById("f8").value = "";
    }
    // history.push("");
    // }
    // e.preventDefault();
  };

  // const handleEdit = (data) => {
  //   setName(data.name);
  //   setPosition(data.position);
  //   setFeedback(data.feedback);
  //   setImage_url(data.image_url);
  //   setTestimonialcrousal_id(data._id);
  //   console.log(data);
  // };

  const handleDelete = async (data) => {
    const result = await api.post("/updatehomerecommend", {
      homerecommend_id: data._id,
      delete_status: true,
    });
    message.success(result.data.message);
    setRefresh("3");
    console.log(result);
  };

  const columns = [
    {
      title: "Project Title",
      dataIndex: "project_title",
      key: "project_title",
      //   render: text => <a>{text}</a>,
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      //   render: text => text.substring(0, 20)
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      //   render: text => text.substring(0, 20)
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => formatDate(date),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => formatDate(date),
    },
    {
      title: "Client Image",
      dataIndex: "client_image",
      key: "client_image",
      render: (url) => <img src={url} height={30} width={30} />,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>
            <Button
              variant="danger"
              onClick={() => {
                const testimonialObj = {
                  ...text,
                  start_date: formatDate2(text.start_date),
                  end_date: formatDate2(text.end_date),
                };
                setEditId(text._id);
                setTestimonial(testimonialObj);
                console.log("testimonial to edit", testimonialObj);
                // setEdit(true);
                setIsEdit(true);
              }}
            >
              <EditOutlined />
            </Button>
          </a>
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleDelete(text);
              }}
            >
              <DeleteOutlined />
            </Button>
          </a>
          {/* <a><Button onClick={handleDelete(text)}>Edit</Button></a> */}
        </Space>
      ),
    },
  ];

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const inputstyle = { width: "30%", margin: "1%", fontStyle: "italic " };
  return (
    <div>
      {/* form for add hero slider   */}
      <Card title="Add Testimonial">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div style={outerstyle}>
            <Form.Control
              style={inputstyle}
              value={testimonial.project_title}
              onChange={(e) => {
                setTestimonial({
                  ...testimonial,
                  project_title: e.target.value,
                });
              }}
              required
              placeholder="Enter Project Title"
            />
            <Form.Control
              style={inputstyle}
              value={testimonial.client_name}
              onChange={(e) => {
                setTestimonial({ ...testimonial, client_name: e.target.value });
              }}
              placeholder="Enter Client Name"
            />
            <Form.Control
              style={inputstyle}
              value={testimonial.rating}
              onChange={(e) => {
                setTestimonial({ ...testimonial, rating: e.target.value });
              }}
              type="number"
              step={1}
              max={5}
              min={1}
              required
              placeholder="Enter Rating"
            />
            <Form.Control
              style={inputstyle}
              value={testimonial.start_date}
              onChange={(e) => {
                setTestimonial({ ...testimonial, start_date: e.target.value });
              }}
              required
              placeholder="Enter Start Date"
              type="date"
            />
            <Form.Control
              style={inputstyle}
              value={testimonial.end_date}
              onChange={(e) => {
                console.log(e.target.value);
                setTestimonial({ ...testimonial, end_date: e.target.value });
              }}
              required
              placeholder="Enter End Date"
              type="date"
            />
            <Form.Control
              style={inputstyle}
              value={testimonial.content}
              onChange={(e) => {
                setTestimonial({ ...testimonial, content: e.target.value });
              }}
              required
              placeholder="Enter Feedback"
              as="textarea"
            />
            <Form.Control
              style={inputstyle}
              id="f8"
              type="file"
              onChange={(e) => {
                handleUpload1(e.target.files[0]);
              }}
            />
            <Button
              variant="success"
              style={{ margin: "1%", width: "8%" }}
              type="submit"
            >
              {isEdit ? "UPDATE" : "ADD"}
            </Button>
          </div>
        </Form>
      </Card>

      <Card title="Testimonials">
        <Table dataSource={data} columns={columns}></Table>
      </Card>
    </div>
  );
}
