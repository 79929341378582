import { Card, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import CardComponent from '../MenuBarComponent/MenuLogoSection/CardComponent';
import api from '../../Helper/API';

export default function BlogSection() {

    // const [serviceTitleList, setServiceTitleList] =useState([]);
    // const [serviceImgList, setServiceImgList] = useState([])
    const [refresh, setRefresh] =useState("xyz")
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitle] = useState("");
    const [text, setText] = useState("");
    const [homeblog_id, sethomeblog_id] =useState("")

 
    useEffect(async() => {
      const result =await api.post("/getHomeblog");
    //   console.log("00",result.data.data)
      setTitle(result.data.data[0].title);
      setSubTitle(result.data.data[0].subtitle);
      setText(result.data.data[0].text);
      sethomeblog_id(result.data.data[0]._id)

    }, [refresh])
    

    const handleServiceTitle=async(e)=>{
        e.preventDefault();
        const result =await api.post("/updateHomeblog",{title:title, subtitle:subtitle, text:text, homeblog_id:homeblog_id});
        message.success(result.data.message)
       setRefresh("1")
    }   



    const outerstyle = {display: "flex", justifyContent: 'flex-start', alignContent: "center",
        flexDirection: "row", flexWrap: 'wrap'  }
      const inputstyle = { width: "20%", margin: "1%",fontStyle:'italic ' }
  return (
    <div className='p-4'>
    <div style={{width:"100%"}}>
      <CardComponent title=" Home Blog Title Setting ">
        <Card  >
        <Form>
            <div style={outerstyle}>
              <div className="col-md-2">
                <label htmlFor=""></label>
              </div>
            <Form.Control style={inputstyle} value={subtitle} onChange={(e) => { setSubTitle(e.target.value) }} placeholder='Enter Blog Sub-Title' />
            <Form.Control style={inputstyle} value={title} onChange={(e) => { setTitle(e.target.value) }} placeholder='Enter Blog Title' />
            <Form.Control style={inputstyle} value={text} onChange={(e) => { setText(e.target.value) }} placeholder='Enter Blog text' />
            <div  style={inputstyle}><Button variant='success' onClick={handleServiceTitle}>UPDATE</Button></div>
            </div>
            
        </Form>
        </Card>
        </CardComponent>
    </div>
    </div>
  )
}
