import React, { useState, useEffect } from 'react';
import { Card, Table, Space, message } from 'antd';
import { Button, Form } from 'react-bootstrap';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import axios from 'axios';
import api from '../../Helper/API';
import CKEditor from "react-ckeditor-component";
import { Buffer } from 'buffer';
import { uploadFile } from '../../Helper/helper';
window.Buffer = window.Buffer || Buffer;

export default function WhyToChooseUsListSection() {

  const [data, setData] = useState([])
  const [tab_name, setTab_name] = useState("");
  const [content, setContent] = useState("");
  const [image_url, setImage_url] = useState("")
  const [url, seturl] =useState("");
  const [edit, setEdit] = useState(false);
  const [aboutwhyustab_id, setaboutwhyustab_id] = useState()
  const [refresh, setRefresh] = useState("start");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload1 = async () => {
    let imgUrl = await uploadFile(selectedFile, `${selectedFile.name}fileimg_${Date.now()}`);
    setImage_url( imgUrl);
}


  useEffect(async () => {

    const res = await api.post("/getWhyUsTab")
    setData(res.data.data)
    //   console.log("11",res)

  }, [refresh])






  const handleSubmit = async (e) => {
    if (edit) {
      console.log("true", edit)
      const result = await api.post("/updateWhyUsTab", {tab_name, image_url ,content ,aboutwhyustab_id})
      message.success(result.data.message)
      setContent(""); setTab_name("");setImage_url("")
      seturl("");
      setEdit(false); setaboutwhyustab_id("");  
      setRefresh("1")
      document.getElementById("f").value="";
    }
    else {
      console.log( "add")
      const result = await api.post("/addWhyUsTab", {content,image_url, tab_name })
      // console.log(result)
      message.success(result.data.message)
      setContent(""); setTab_name("");
      setEdit(false); setaboutwhyustab_id("");  setImage_url("")
      setRefresh("2")
      document.getElementById("f").value="";




    }
    // e.preventDefault();
  }

  const handleEdit = (data) => {
     setContent(data.content); 
    setTab_name(data.tab_name)
    setaboutwhyustab_id(data._id)
    setImage_url(data.image_url)
    console.log(data)
    setRefresh("6")
  }

  const handleDelete = async(data) => {
   const result =await api.post("/updateWhyUsTab",{aboutwhyustab_id:data._id, delete_status:true})
   message.success(result.data.message)
   setRefresh("9")
   console.log(result)
  }

  function onChange(evt){
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
   setContent(newContent)
  }


  const columns = [
    {
      title: 'Tab Title',
      dataIndex: 'tab_name',
      key: 'tab_name',
      //   render: text => <a>{text}</a>,
    },
    {
      title: 'Tab Description',
      dataIndex: 'content',
      key: 'content',
      render: text => text.substring(0, 20)

    },
    {
      title: 'Image URL',
      dataIndex: 'image_url',
      key: 'image_url',
      render: text => text.substring(0, 20)

    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a><Button variant='danger' onClick={() => { handleEdit(text); setEdit(true); }}><EditOutlined /></Button></a>
          <a><Button variant='danger' onClick={() => { handleDelete(text) }}><DeleteOutlined /></Button></a>
          {/* <a><Button onClick={handleDelete(text)}>Edit</Button></a> */}

        </Space>
      ),
    },
  ];

  const outerstyle = {
    display: "flex", justifyContent: 'flex-start', alignContent: "center",
    flexDirection: "column", flexWrap: 'wrap'
  }
  const inputstyle = { width: "65%", margin: "1%" }
  return (
    <div>

      {/* form for add hero slider   */}
      <Card title="Why To Choose Us Tab Setting">
        <Form>
          <div style={outerstyle}>
           <Form.Control style={inputstyle} value={tab_name} onChange={(e) => { setTab_name(e.target.value) }} placeholder='Enter Tab title' />
           <Form.Control style={inputstyle} id="f"onBlur={handleUpload1} type="file" onChange={(e)=>{setSelectedFile(e.target.files[0])}} />           
           <Form.Control style={inputstyle} value={image_url} onChange={(e) => { setImage_url(e.target.value) }} placeholder='Enter Tab Discription Image URL' />
          
            {/* <div ><Button onClick={() => handleUpload1()}> UPLOAD</Button></div> */}
 
        <div style={{margin:"1%"}}><CKEditor style={{margin:"1%"}}
              activeClass="p10" 
              content={content} 
              events={{
                // "blur": onBlur,
                // "afterPaste": afterPaste,
                "change": onChange
              }}
             /></div>


              <Button  variant='success' style={{ margin: "1%", width: "8%" }} onClick={handleSubmit}>{edit?"UPDATE":"ADD "}</Button>
          </div>
        </Form>
      </Card>

      <Card title="Why To Choose Us Tab List ">

        <Table dataSource={data} columns={columns} >

        </Table>

      </Card>
    </div>
  )
}
