import { message } from 'antd';
import React, { useState, useEffect } from 'react';
import api from '../../Helper/API';
import { uploadFile } from "../../Helper/helper";
import { Buffer } from 'buffer';

import './MenuLogoSection.css'
import MenuContainer from './MenuLogoSection/MenuContainer';
import CardComponent from './MenuLogoSection/CardComponent';
import FormComponent from './MenuLogoSection/FormComponent';

window.Buffer = window.Buffer || Buffer;

export default function MenuLogoSection() {
    const [refresh, setRefresh] = useState("xyz");
  const [logo_url, setlogo_url] = useState("");
  const [logo_id, setLogo_id] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const handleUpload1 = async () => {
    let imgUrl = await uploadFile(
      selectedFile,
      `${selectedFile.name}fileimg_${Date.now()}`
    );
    setlogo_url(imgUrl);
    document.getElementById("f").value = "";
  };

  useEffect(async () => {
    const result = await api.post("/getLogo");
    console.log(result.data.data);
    setlogo_url(result.data.data[0].logo_url);
    setLogo_id(result.data.data[0]._id);
  }, [refresh]);

  const handlelogo = async (e) => {
    e.preventDefault();
    const result = await api.post("/updateLogo", { logo_url, logo_id });
    console.log("first", result);
    message.success(result.data.message);
    setRefresh("1");
  };

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    flexWrap: "wrap",
  };
  const inputstyle = {
    
    fontStyle: "italic ",
    borderRadius: "5px",
    width:"100%"
  };
  const buttonstyle = { borderRadius: "5px",backgroundColor:"#2B3A4A",border:"none" };

  return (
  <div className='p-4'>
      <MenuContainer>
      <CardComponent title="Menu Bar Logo Setting">
        <FormComponent
          onFileChange={(e) => {
            setSelectedFile(e.target.files[0]);
          }}
          onFileBlur={handleUpload1}
          logoUrl={logo_url}
          onUrlChange={(e) => {
            setlogo_url(e.target.value);
          }}
          onSubmit={handlelogo}
        />
      </CardComponent>
    </MenuContainer>
  </div>
  );
}



























