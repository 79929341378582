import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import store, { persistor } from "./State/store";
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from "react-router-dom";

const Loader = () => {
  const spinnerIcon = (
    <LoadingOutlined
      style={{ fontSize: 50, justifySelf: "center", alignSelf: "center" }}
      spin
    />
  );
  return (
    <div className="loader-container">
      <Spin indicator={spinnerIcon} />
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
    <Provider store={store} >
      <PersistGate loading={<Loader />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider> 
</BrowserRouter>
</React.StrictMode>,
  document.getElementById('root')
);



reportWebVitals();

