import { Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import api from "../../Helper/API";
import CardComponent from "../MenuBarComponent/MenuLogoSection/CardComponent";

export default function PortfolioSection() {
  const [refresh, setRefresh] = useState("xyz");
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [homeportfolio_id, sethomeportfolio_id] = useState("");

  // const [serviceImg, setServiceImg] = useState();
  // const [imgText, setImgText] =useState("");
  // const [destinationURL, setDestinationURL] =useState("");

  useEffect(async () => {
    const result = await api.post("/getHomePortfolio");
    //   console.log(result.data.data)
    setTitle(result.data.data[0].title);
    setSubTitle(result.data.data[0].subtitle);
    setText(result.data.data[0].text);
    sethomeportfolio_id(result.data.data[0]._id);

    //   const res = await api.post("");
    //   setServiceImgList(res.data.data)
  }, [refresh]);

  const handlePortfolioTitle = async (e) => {
    e.preventDefault();
    const result = await api.post("/updateHomePortfolio", {
      title,
      subtitle,
      text,
      homeportfolio_id,
    });
    message.success(result.data.message);
    setRefresh("1");
  };

  const handleServiceImage = () => {};

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };

  const labelstyle={fontSize:"medium " ,fontWeight:"500"}
  const inputstyle = { width: "30%", margin: "2%", fontStyle: "italic " ,borderRadius: '5px'};
  return (
    <div className="p-4">
      <div style={{ width: "100%" }}>
        <CardComponent title={" Portfolio title Setting "}>
          <Card>
            <Form className="pt-2 p-0" style={{background:"none"}}>
              <div style={outerstyle}>
                <div className="col-md-2 ">
                  <label className="pt-md-4" style={labelstyle} >Portfolio-subtitle</label>
                </div>
                <div className="col-md-10">
                <Form.Control
                className="w-100"
                  style={inputstyle}
                  value={subtitle}
                  onChange={(e) => {
                    setSubTitle(e.target.value);
                  }}
                  placeholder="Enter Portfolio Sub-Title"
                />
                </div>
              <div className="col-md-2 ">
                <label className="pt-md-4" style={labelstyle} >Portfolio Title</label>
              </div>
              <div className="col-md-10">
              <Form.Control
                 className="w-100"
                  style={inputstyle}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  placeholder="Enter Portfolio Title"
                />
              </div>
              <div className="col-md-2 ">
                <label className="pt-md-4" style={labelstyle} >Portfolio text</label>
              </div>
              <div className="col-md-10">
              <Form.Control
                 className="w-100"
                  style={inputstyle}
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  placeholder="Enter Portfolio text"
                />
              </div>
                <div className="my-3">
                  <Button 
                   style={{ borderRadius: '5px', backgroundColor: '#2B3A4A', border: 'none'  }}
                  variant="success"
                   onClick={handlePortfolioTitle}>
                    UPDATE
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </CardComponent>
        <br />
        <br />
        {/* <Card title="Portfolio Image Setting  ">
        <Form>
            <div style={outerstyle}>
            <Form.Control style={inputstyle} value={serviceImg} onChange={(e) => { setServiceImg(e.target.value) }} placeholder='Enter Service Sub-Title' />
            <Form.Control style={inputstyle} value={imgText} onChange={(e) => { setImgText(e.target.value) }} placeholder='Enter Image Text' />
            <Form.Control style={inputstyle} value={destinationURL} onChange={(e) => { setDestinationURL(e.target.value) }} placeholder='Enter Destination URL' />
            <div  style={inputstyle} onClick={handleServiceImage}><Button>Update</Button></div>
            </div>
            
        </Form> 
        </Card> */}
      </div>
    </div>
  );
}
