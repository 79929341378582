import { Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import api from "../../Helper/API";
import CardComponent from "../MenuBarComponent/MenuLogoSection/CardComponent";

export default function ServiceSection() {
  // const [serviceTitleList, setServiceTitleList] =useState([]);
  // const [serviceImgList, setServiceImgList] = useState([])
  const [refresh, setRefresh] = useState("xyz");
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [text, setText] = useState("");
  const [homeservices_id, setHomesrvices_id] = useState("");

  const [serviceImg, setServiceImg] = useState();
  const [imgText, setImgText] = useState("");
  const [destinationURL, setDestinationURL] = useState("");

  useEffect(async () => {
    const result = await api.post("/getHomeServices");
    //   console.log(result.data.data)
    setTitle(result.data.data[0].title);
    setSubTitle(result.data.data[0].subtitle);
    setText(result.data.data[0].text);
    setHomesrvices_id(result.data.data[0]._id);

    //   const res = await api.post("");
    //   setServiceImgList(res.data.data)
  }, [refresh]);

  const handleServiceTitle = async (e) => {
    e.preventDefault();
    const result = await api.post("/updateHomeServices", {
      title: title,
      subtitle: subtitle,
      text: text,
      homeservices_id: homeservices_id,
    });
    message.success(result.data.message);
    setRefresh("1");
  };

  const handleServiceImage = () => {};

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const labelstyle = { fontSize: "medium ", fontWeight: "500" };
  const inputstyle = {
    width: "30%",
    margin: "2%",
    fontStyle: "italic ",
    borderRadius: "5px",
  };
  return (
    <div className="p-4">
      <div style={{ width: "100%" }}>
        <CardComponent title=" Home Services Title Setting ">
          <Card>
            <Form className="pt-2 p-0" style={{ background: "none" }}>
              <div style={outerstyle}>
                <div className="col-lg-2">
                
                    <label className="pt-md-4" style={labelstyle}>Service Sub-Title</label>
                 
                </div>
                <div className="col-md-10">
                 
                  <Form.Control
                   className="w-100"
                  style={inputstyle}
                  value={subtitle}
                  onChange={(e) => {
                    setSubTitle(e.target.value);
                  }}
                  placeholder="Enter Service Sub-Title"
                />
              
                </div>
                <div className="col-lg-2">
                 
                    <label label className="pt-md-4" style={labelstyle}>Service Title</label>
                 
                </div>
                <div className="col-lg-10">
                  
                  <Form.Control
                   className="w-100"
                  style={inputstyle}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  placeholder="Enter Service Title"
                />
                
                </div>
                 <div className="col-lg-2">
                  
                    <label label className="pt-md-4" style={labelstyle}>Service text</label>
                  
                 </div>
                 <div className="col-lg-10">
                  

                <Form.Control
                 className="w-100"
                  style={inputstyle}
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  placeholder="Enter Service text"
                />
                  
                 </div>
            
                <div className="my-3">
                  <Button 
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#2B3A4A",
                    border: "none",
                  }}
                  variant="success" onClick={handleServiceTitle}>
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </CardComponent>
      </div>
    </div>
  );
}
