import { Card } from 'antd'
import React from 'react'
import HeroSliderForm from '../Component/HeroSliderForm'

export default function AddHeroSlider() {
  return (
    <div>
      
      <Card title="Hero Slider ">
         <HeroSliderForm/>
          </Card>
    </div>
  )
}
