import { Card, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import api from '../../Helper/API';

export default function WhyToChooseUsTitleSection() {

    const [refresh, setRefresh] =useState("xyz")
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitle] = useState("");
    const [text, setText] = useState("");
    const [aboutwhyustab_id, setaboutwhyustab_id] =useState("")

    
    useEffect(async() => {
      const result =await api.post("/getWhyUs");
      console.log(result.data.data)
      setTitle(result.data.data[0].title);
      setSubTitle(result.data.data[0].subtitle);
      setText(result.data.data[0].text);
      setaboutwhyustab_id(result.data.data[0]._id)

    }, [refresh])
    

    const hanldetitle=async(e)=>{
        e.preventDefault();
        const result =await api.post("/updateWhyUs",
        {title, subtitle, text, aboutwhyus_id:aboutwhyustab_id});
        message.success(result.data.message)
       setRefresh("1")
    }   

  



    const outerstyle = {display: "flex", justifyContent: 'flex-start', alignContent: "center",
        flexDirection: "row", flexWrap: 'wrap'  }
      const inputstyle = { width: "22%", margin: "1%" ,fontStyle:'italic '}
  return (
    <div>
        <Card title="  Why To Choose Us Section Title Setting " >
        <Form>
            <div style={outerstyle}>
            <Form.Control style={inputstyle} value={subtitle} onChange={(e) => { setSubTitle(e.target.value) }} placeholder='Enter WhytoChooseus Sub-Title' />
            <Form.Control style={inputstyle} value={title} onChange={(e) => { setTitle(e.target.value) }} placeholder='Enter WhytoChooseus Title' />
            <Form.Control style={inputstyle} value={text} onChange={(e) => { setText(e.target.value) }} placeholder='Enter WhytoChooseus text' />
            <div  style={inputstyle}><Button variant="success" onClick={hanldetitle}>UPDATE</Button></div>
            </div>
            
        </Form>
        </Card>

       
        
    </div>
  )
}
