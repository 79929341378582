import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

const FormComponent = ({ onFileChange, onFileBlur, logoUrl, onUrlChange, onSubmit }) => {
  return (
    <Form className="m-1 p-4">
      <Row className="pb-2">
        <Col lg={12}>
          <div className="row w-100 my-3" style={{ alignItems: 'center' }}>
            <div className="col-2">
              <label style={{ fontSize: 'medium', fontWeight: '500' }}>Upload File</label>
            </div>
            <div className="col-10">
              <Form.Control
                style={{ fontStyle: 'italic', borderRadius: '5px', width: '100%' }}
                id="f"
                type="file"
                onBlur={onFileBlur}
                onChange={onFileChange}
              />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="row w-100 my-3" style={{ alignItems: 'center' }}>
            <div className="col-2">
              <label style={{ fontSize: 'medium', fontWeight: '500' }}>File URL</label>
            </div>
            <div className="col-10">
              <Form.Control
                style={{ fontStyle: 'italic', borderRadius: '5px', width: '100%' }}
                value={logoUrl}
                onChange={onUrlChange}
                placeholder="Enter logo url"
              />
            </div>
          </div>
        </Col>
      </Row>

      <div className="my-3" style={{ fontStyle: 'italic', borderRadius: '5px', width: '100%' }}>
        <Button
          style={{ borderRadius: '5px', backgroundColor: '#2B3A4A', border: 'none' }}
          variant="danger"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Form>
  );
};

export default FormComponent;
