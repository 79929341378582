import { Card, message, Table, Space } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import api from "../Helper/API";

import { Buffer } from "buffer";
import { uploadFile } from "../Helper/helper";
window.Buffer = window.Buffer || Buffer;

export default function ServicesListSection() {
  const [refresh, setRefresh] = useState("xyz");
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [image_url, setimage_url] = useState("");
  const [icon_url, seticon_url] = useState("");
  const [heading, setheading] = useState("");
  const [content, setcontent] = useState("");
  const [services_id, setservices_id] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload1 = async () => {
    let imgUrl = await uploadFile(
      selectedFile,
      `${selectedFile.name}fileimg_${Date.now()}`
    );
    setimage_url(imgUrl);
    document.getElementById("f1").value = "";
  };
  //   const handleUpload2 = async () => {
  //       let imgUrl = await uploadFile(selectedFile, `${selectedFile.name}fileimg_${Date.now()}`);
  //       seticon_url( imgUrl);
  // }

  useEffect(async () => {
    const res = await api.post("/getServices");
    console.log("2", res.data.data);
    setData(res.data.data);
  }, [refresh]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (edit) {
      // alert("edit")
      const result = await api.post("/updateServices", {
        image_url,
        icon_url,
        heading,
        content,
        services_id,
      });
      message.success(result.data.message);
      setEdit(false);
      setimage_url("");
      seticon_url("");
      setheading("");
      setcontent("");
      setRefresh("2");
    } else {
      // alert("add")
      const result = await api.post("/addServices", {
        image_url,
        icon_url,
        heading,
        content,
        services_id,
      });
      message.success(result.data.message);
      setEdit(false);
      setimage_url("");
      seticon_url("");
      setheading("");
      setcontent("");

      setRefresh("3");
    }
  };

  const handleEdit = (data) => {
    setimage_url(data.image_url);
    seticon_url(data.icon_url);
    setheading(data.heading);
    setcontent(data.content);
    setservices_id(data._id);
    setEdit(true);
    console.log(data);
  };

  const handleDelete = async (data) => {
    const result = await api.post("/updateServices", {
      services_id: data._id,
      delete_status: true,
    });
    message.success(result.data.message);
    setRefresh("4");
    // console.log(result)
  };

  const columns = [
    {
      title: "Image URL",
      dataIndex: "image_url",
      key: "image_url",
      render: (text) => text.substring(0, 20).concat("..."),

      //   render: text => <a>{text}</a>,
    },
    {
      title: "Icon_URL",
      dataIndex: "icon_url",
      key: "icon_url",
      render: (text) => text.substring(0, 20).concat("..."),
    },
    {
      title: " Service Heading",
      dataIndex: "heading",
      key: "heading",
      render: (text) => text.substring(0, 20).concat("..."),
    },
    {
      title: " Content",
      dataIndex: "content",
      key: "content",
      render: (text) => text.substring(0, 20).concat("..."),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleEdit(text);
                setEdit(true);
              }}
            >
              <EditOutlined />
            </Button>
          </a>
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleDelete(text);
              }}
            >
              <DeleteOutlined />
            </Button>
          </a>
          {/* <a><Button onClick={handleDelete(text)}>Edit</Button></a> */}
        </Space>
      ),
    },
  ];

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const inputstyle = { width: "30%", margin: "1%", fontStyle: "italic " };
  return (
    <div style={{ width: "100%" }}>
      <Card title="Add Services Here!  ">
        <Form>
          <div style={outerstyle}>
            <Form.Control
              style={inputstyle}
              id="f1"
              onBlur={handleUpload1}
              type="file"
              onChange={(e) => {
                setSelectedFile(e.target.files[0]);
              }}
            />
            {/* <div style={{margin:"1%"}}><Button onClick={() => handleUpload1()}> Upload</Button></div> */}
            <Form.Control
              style={inputstyle}
              value={image_url}
              onChange={(e) => {
                setimage_url(e.target.value);
              }}
              placeholder="Enter image URL"
            />
            {/* <Form.Control style={inputstyle} type="file" onChange={(e)=>{setSelectedFile(e.target.files[0])}} />
            <div style={{margin:"1%"}}><Button onClick={() => handleUpload2()}> Upload</Button></div> */}
            <Form.Control
              style={inputstyle}
              value={icon_url}
              onChange={(e) => {
                seticon_url(e.target.value);
              }}
              placeholder="Enter ClassName of Font Awesome Icon"
            />
            <Form.Control
              style={inputstyle}
              value={heading}
              onChange={(e) => {
                setheading(e.target.value);
              }}
              placeholder="Enter Services Heading "
            />

            <Form.Control
              as="textarea"
              rows={3}
              style={inputstyle}
              value={content}
              onChange={(e) => {
                setcontent(e.target.value);
              }}
              placeholder="Write Content or description"
            />

            <div style={inputstyle}>
              <Button variant="success" onClick={handlesubmit}>
                {edit ? "UPDATE" : "ADD"}
              </Button>
            </div>
          </div>
        </Form>
      </Card>
      <Card title="Services List">
        <Table dataSource={data} columns={columns}></Table>
      </Card>
    </div>
  );
}
