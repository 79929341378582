import React from "react";
import TestimonialHeadSection from "./TestimonialHeadSection";
import TestimonialCrousalSection from "./TestimonialCrousalSection";

const TestimonialSection = () => {
  return (
    <>
      <div>
        {/* <TestimonialHeadSection/> */}
        <TestimonialCrousalSection />
      </div>
    </>
  );
};

export default TestimonialSection;
